import Siema from './Siema.js'
import debounce from 'lodash.debounce'
import fastdom from 'fastdom'
import PhotoSwipe from 'photoswipe'
import PhotoSwipeUIDefault from 'photoswipe/dist/photoswipe-ui-default'

export default class ProductSlider {
  constructor (parent, {
    classes,
    scrollLength = 2,
    siemaOpts,
    photoSwipe = false,
    onInit
  } = {}) {
    this.parent = typeof parent === 'string' ? document.querySelector(parent) : parent
    if (!this.parent) return

    this.classes = this.getClasses(classes)

    this.photoWrapper = this.parent.querySelector(`.${this.classes.photoWrapper}`)

    this.thumbsWrapper = this.parent.querySelector(`.${this.classes.thumbsWrapper}`)
    this.thumbsScrollWrapper = this.parent.querySelector(`.${this.classes.thumbsScrollWrapper}`)
    this.thumbList = Array.from(this.thumbsScrollWrapper.children)
    this.activeThumb = this.thumbsWrapper.querySelector(`.${this.classes.thumbActive}`)

    this.scrollLength = scrollLength

    this.thumbsWrapper.addEventListener('click', this.onThumbsWrapperClick.bind(this))

    fastdom.mutate(this.recalculateVariables, this)
    document.addEventListener('resize', debounce(this.recalculateVariables.bind(this), 300))

    // горизонтальный слайдер с поддержкой тач-жестов
    this.siema = this.initSiema(siemaOpts)

    // слайдер во весь экран
    if (photoSwipe) {
      this.photoSwipe = photoSwipe
      this.$pswp = document.querySelector('.pswp')

      this.photoWrapper.addEventListener('click', this.onPhotoClick.bind(this))
      window.addEventListener('load', this.getPhotoSwipeData.bind(this))
    }

    if (onInit) onInit()
  }

  getClasses (fromParams) {
    return Object.assign({
      img: 'product-slider__photo-img',
      photo: 'product-slider__photo',
      photoActive: 'product-slider__photo_active',
      photoWrapper: 'product-slider__photos',
      onPhotoDrag: 'product-slider__photos_drag',
      thumb: 'product-slider__thumb',
      thumbActive: 'product-slider__thumb_active',
      thumbsWrapper: 'product-slider__thumbs',
      thumbsScrollWrapper: 'product-slider__thumbs-wrapper'
    }, fromParams)
  }

  initSiema (opts) {
    const self = this
    return new Siema(this.parent, {
      selector: `.${this.classes.photoWrapper}`,
      markCurrent: true,
      classes: {
        active: this.classes.photoActive,
        onDrag: this.classes.onPhotoDrag
      },
      wrapperStyle: {
        display: 'block'
      },
      onChange () {
        const thumb = self.getThumbByIndex(this.currentSlide)
        thumb.classList.add(self.classes.thumbActive)
        self.onThumbClick(thumb)
      },
      ...opts
    })
  }

  onThumbsWrapperClick (e) {
    const thumb = e.target.closest(`.${this.classes.thumb}`)
    if (!thumb) return
    this.onThumbClick(thumb)
  }

  onThumbClick (thumb) {
    const scrollTop = this.thumbsScrollWrapper.scrollTop
    const thumbPosition = thumb.offsetTop - scrollTop
    let y = null

    if (thumbPosition < this.thumbsScrollWrapperHeight * 0.25) { // если в верхней четверти
      y = scrollTop - this.thumbHeight * this.scrollLength
      if (y < 0) y = 0
    } else if (thumbPosition > this.thumbsScrollWrapperHeight * 0.75) { // если в нижней четверти
      y = scrollTop + this.thumbHeight * this.scrollLength
      if (y > this.maxScrollY) y = this.maxScrollY
    }

    // если позиция скролла обновилась
    if (y !== null) {
      this.thumbsScrollWrapper.scrollTo({
        top: y,
        behavior: 'smooth'
      })
    }

    // ресетим превьюшки и изменяем позицию основного слайдера
    this.toggle(this.getThumbIndex(thumb))
  }

  toggle (index, {
    siemaOpts
  } = {}) {
    this.siema.goTo(index, siemaOpts)
    this.toggleThumb(index)
  }

  toggleThumb (index) {
    this.resetActiveThumb()
    this.activeThumb = this.getThumbByIndex(index)
    this.activeThumb.classList.add(this.classes.thumbActive)
  }

  getThumbIndex (el) {
    return this.thumbList.indexOf(el)
  }

  getThumbByIndex (index) {
    return this.thumbList[index]
  }

  resetActiveThumb () {
    if (this.activeThumb) this.activeThumb.classList.remove(this.classes.thumbActive)
  }

  recalculateVariables () {
    this.thumbsScrollWrapperHeight = this.thumbsScrollWrapper.offsetHeight
    this.thumbHeight = this.parent.querySelector(`.${this.classes.thumb}`).offsetHeight
    this.maxScrollY = this.thumbsScrollWrapper.scrollHeight - this.thumbsScrollWrapperHeight
  }

  getPhotoSwipeData () {
    this.pswpData = []
    const photos = Array.from(this.parent.querySelectorAll(`.${this.classes.img}`))
    photos.forEach((el) => {
      this.pswpData.push({
        src: el.dataset.src,
        msrc: el.src,
        w: parseInt(el.dataset.width),
        h: parseInt(el.dataset.height)
      })
    })
  }

  onPhotoClick (e) {
    const target = e.target.closest('img')
    if (target && this.pswpData && !this.siema.isDragActive()) this.initPhotoSwipe()
  }

  initPhotoSwipe () {
    const self = this
    let startFromPhoto
    this.setAdditionalText()

    const photoSwipe = new PhotoSwipe(this.$pswp, PhotoSwipeUIDefault, this.pswpData, {
      index: this.getThumbIndex(this.activeThumb),
      getThumbBoundsFn () {
        return self.getThumbBoundsFn()
      },
      shareEl: false,
      closeElClasses: [
        'p',
        'h3',
        'additional-text',
        'item',
        'caption',
        'zoom-wrap',
        'ui',
        'top-bar',
        'img'
      ]
    })

    photoSwipe.listen('afterChange', () => {
      self.toggle(photoSwipe.getCurrentIndex())
    })

    photoSwipe.listen('initialZoomIn', () => {
      startFromPhoto = self.photoWrapper.querySelector(`.${self.classes.photoActive} .${self.classes.img}`)
      startFromPhoto.style.opacity = 0
    })

    photoSwipe.listen('destroy', () => {
      startFromPhoto.style.opacity = 1
      self.clearAdditionalText()
    })

    photoSwipe.init()
  }

  setAdditionalText () {
    if (this.photoSwipe.setTitle) this.photoSwipe.setTitle(this.$pswp)
    if (this.photoSwipe.setSubtitle) this.photoSwipe.setSubtitle(this.$pswp)
  }

  clearAdditionalText () {
    this.$pswp.querySelector('.pswp__additional-text .h3').innerText = ''
    this.$pswp.querySelector('.pswp__additional-text .p').innerText = ''
  }

  getThumbBoundsFn () {
    const photo = this.photoWrapper.querySelector(`.${this.classes.photoActive} .${this.classes.img}`)
    const rect = photo.getBoundingClientRect()
    return {
      x: rect.left,
      y: rect.top + window.pageYOffset,
      w: rect.width
    }
  }
}
