// Универсальные скрипты для мобильной и десктопной версии

// Сторонни библиотеки
import ElementQueries from 'css-element-queries/src/ElementQueries'

import tippy from 'tippy.js'
import 'dragscroll'
import LazyLoad from 'vanilla-lazyload'

// Наши модули
import Toggle from './component/Toggle.js'
import {
  FitListToBlock
} from './component/FitListToBlock'
import Modal from './component/Modal.js'
import TextareaDynamicHeight from './component/TextareaDynamicHeight.js'
import Siema from './component/Siema.js'
import TabManager from './component/TabManager.js'
import ProductSlider from './component/ProductSlider'
import PhotoZoom from './component/PhotoZoom.js'
import ProductRating from './component/ProductRating'
import ColorAvatar from './component/ColorAvatar'
import NotificationPopUp from './component/NotificationPopUp'
import RangeInput from './component/RangeInput'
import InputNote from './component/InputNote'
import SimpleCountdown from './component/SimpleCountdown'
import TogglePasswordVisibility from './component/TogglePasswordVisibility'
import CopyToClickboard from './component/CopyToClickboard'
import ShowHidden from './component/ShowHidden'
import {
  CompareController,
  toggleCompare,
  getCompare
} from './component/CompareController'
import SimpleSlider from './component/SimpleSlider'
import ListSearch from './component/ListSearch'
import PhotoswipePhotoGrid from './component/PhotoswipePhotoGrid'
import FilterList from './component/FilterList'
import DefferComponentInit from './component/DefferComponentInit'
import ToolTooltip from './component/ToolTooltip'
import DisableLink from './component/DisableLink'
import shareToGlobal from './component/ShareToGlobal'

window.addEventListener('load', () => {
  // слайдеры
  shareToGlobal.share('Siema', Siema)

  // "выключение" ссылок при определенном размере окна
  DisableLink()

  // медиа выражения на основе ширины элементов, а не окна
  ElementQueries.init()

  // Формы на странице Кредита и рассрочки
  TabManager.init({
    parentSelector: '.bank-block_with-form',
    tab: 'bank-block__calc',
    wrapper: 'bank-block__form',
    onChange (wrapper) {
      if (!wrapper.dataset.formIsActive) {
        // Инпуты со слайдером и подсказкой рядом с полем вода
        const arr = Array.from(wrapper.querySelectorAll('.range-input_js-inside-bank-form'))

        this.rangeInputArr = []
        arr.forEach((el) => {
          this.rangeInputArr.push(
            new RangeInput(el, {
              onInit ({
                parent
              }) {
                this.InputNote = new InputNote(parent.querySelector('.input-note_inside-range-input'))
              },
              onRangeChange ({
                value
              }) {
                this.InputNote.change({
                  value,
                  passive: true
                })
              }
            })
          )
        })
        wrapper.dataset.formIsActive = true
      } else {
        // при повторном открытии обновляем вид, если изменилось, например, разрешение экрана
        this.rangeInputArr.forEach((el) => {
          el.rangeInstance._update()
        })
      }
    }
  })

  // Инпуты со слайдером и подсказкой рядом с полем вода
  RangeInput('.range-input_js-input-note', {
    initialPlaceholderSync: true,
    onInit ({
      parent
    }) {
      this.InputNote = new InputNote(parent.querySelector('.input-note_inside-range-input'))
    },
    onRangeChange ({
      value
    }) {
      this.InputNote.change({
        value,
        passive: true
      })
    }
  })

  // Инпуты со слайдером
  RangeInput()

  // компонент рейтинга на странице сборки компьютера
  ProductRating('.bem-product-rating_interactive', {
    classes: {
      button: {
        base: 'bem-product-rating__el',
        active: 'bem-product-rating__el_active'
      }
    }
  })

  // Инпуты с подсказкой рядом с полем ввода
  InputNote()

  // Автогенерируемые аватарки
  ColorAvatar('.color-avatar')

  // Инициализация модальных окон
  Modal.init('.bem-modal-show')

  /* показ скрытого блока при клике на активатор */
  ShowHidden()

  // Textarea высота которой увеличивается по мере набора текста
  TextareaDynamicHeight('.bem-input_textarea-dynamic-height')

  /* стандартные вкладки */
  TabManager.init({
    parentSelector: '.tab-toggle',
    tab: 'tab-toggle__tab',
    wrapper: 'tab-toggle__content'
  })

  /* вкладки на странице продукта */
  TabManager.init({
    parentSelector: '.product-page__description',
    tab: 'tabs-row__tab',
    wrapper: 'product-page__tab-content'
  })

  /* вкладки для горизонтального блока с товарами */
  function productSliderWithTabs (wrapper) {
    return new Siema(wrapper, {
      selector: '.row-slider__slider',
      slideWidth: '.row-slider__product',
      wrapperStyle: {
        display: 'block'
      },
      arrows: true,
      arrowSlideThrough: 3,
      classes: {
        prev: 'row-slider__arrow_left',
        next: 'row-slider__arrow_right'
      }
    })
  }

  TabManager.init({
    parentSelector: '.row-slider_with-tabs',
    tab: 'underline-tabs__tab',
    wrapper: 'row-slider__content',
    onInit (wrapper) {
      productSliderWithTabs(wrapper)
    },
    onChange (wrapper) {
      if (!wrapper.dataset.siemaIsActive) {
        productSliderWithTabs(wrapper)
      }
    }
  })

  // слайдер элементов с кастомными размерами
  Siema.init('.row-slider_custom-el', {
    selector: '.row-slider__slider',
    slideWidth: '.row-slider-custom-el',
    wrapperStyle: {
      display: 'block'
    },
    arrows: true,
    arrowSlideThrough: 1,
    classes: {
      prev: 'row-slider__arrow_left',
      next: 'row-slider__arrow_right',
      notEnoughSlides: 'row-slider_disable-arrows'
    }
  })

  // Одиночный слайдер товаров
  Siema.init('.row-slider_single', {
    selector: '.row-slider__slider',
    slideWidth: '.row-slider__product',
    wrapperStyle: {
      display: 'block'
    },
    arrows: true,
    arrowSlideThrough: 3,
    classes: {
      prev: 'row-slider__arrow_left',
      next: 'row-slider__arrow_right',
      notEnoughSlides: 'row-slider_disable-arrows'
    }
  })

  // Слайдер баннеров акций и новостей
  Siema.init('.main-slider', {
    selector: '.main-slider__slides',
    cloneCount: 2,
    loop: true,
    autoplay: 3200,
    markCurrent: true,
    asideSlideClickNav: true,
    checkSlidesFill: false,
    classes: {
      active: 'main-slider__slide-wrapper_active'
    }
  })

  // Слайдер узких баннеров во всю ширину
  Siema.init('.banner-slider', {
    selector: '.banner-slider__wrapper',
    arrows: true,
    classes: {
      prev: 'banner-slider__arrow_left',
      next: 'banner-slider__arrow_right',
      notEnoughSlides: 'banner-slider_disable-arrows'
    },
    wrapperStyle: {
      display: 'block'
    }
  })

  // слайдер на странице продукта
  new ProductSlider('.product-slider', {
    photoSwipe: {
      setTitle (wrapper) {
        let name = document.querySelector('#product-name')
        let price = document.querySelector('#product-price')

        name = name ? name.innerText.trim() : ''
        price = price ? price.innerText.trim() : ''
        wrapper.querySelector('.pswp__additional-text .h3').innerText = `${name} ${price}`
      },
      setSubtitle (wrapper) {
        let article = document.querySelector('#product-article')

        article = article ? article.innerText.trim() : ''
        wrapper.querySelector('.pswp__additional-text .p').innerText = article
      }
    },
    onInit () {
      // увеличение фотографий при наведении на странице товара
      window.requestAnimationFrame(() => {
        new PhotoZoom({
          selector: '.product-slider__photo-img',
          minViewportWidth: 800
        })
      })
    }
  })

  // компонент отвечающий за скрытие/показ пароля
  TogglePasswordVisibility({
    field: 'bem-input-icon_toggle-password',
    input: 'bem-input-icon__input',
    button: 'bem-input-icon__icon'
  })

  // копирование значения в буфер
  CopyToClickboard()

  // для страницы сравнения: вкладки и сама логика работы страницы
  function initializeCompare (wrapper) {
    if (!wrapper.dataset.compareGridInitialized) {
      wrapper.dataset.compareGridInitialized = true
      CompareController({
        container: wrapper.querySelector('.compare-grid')
      })
    } else {
      const compare = getCompare(wrapper.querySelector('.compare-grid'))

      compare.onResize()
    }
  }

  TabManager.init({
    parentSelector: '.compare-page',
    tab: 'tabs-row__tab',
    wrapper: 'compare-page__content',
    hideOnOneTab: true,
    hideClass: 'compare-page_hide-tabs',
    onInit: initializeCompare,
    onChange (wrapper) {
      toggleCompare(false, {
        reverse: [wrapper.querySelector('.compare-grid')]
      })
      initializeCompare(wrapper)
    }
  })

  // тултипы
  tippy('.js-tippy', {
    theme: 'translucent'
  })

  // слайдер для табов
  SimpleSlider('.tabs-row_js-overflow', {
    slideWidth: 'auto',
    windowMinWidth: 800,
    classes: {
      slider: {
        base: 'tabs-row__slider'
      },
      col: {
        base: 'tabs-row__tab'
      },
      arrow: {
        base: 'tabs-row__arrow',
        active: 'tabs-row__arrow_active',
        left: 'tabs-row__arrow_left',
        right: 'tabs-row__arrow_right'
      }
    }
  })

  /* Валидация формы консультаций */
  const feedbackForm = document.querySelector('.feedback-form:not(.feedback-form_without-validation)')

  if (feedbackForm) {
    feedbackForm.addEventListener('submit', (e) => {
      const phoneInput = e.target.querySelector('.feedback-form__input:first-child')
      const submitButton = e.target.querySelector('.feedback-form__submit')
      const validateBlock = e.target.querySelector('.feedback-form__validation')

      if (phoneInput.value.length > 0) {
        submitButton.classList.add('feedback-form__submit_is-sended')
        submitButton.value = 'Заявка отправлена'
        validateBlock.style.display = 'none'
      } else {
        validateBlock.style.display = 'block'
        e.preventDefault()
      }
    })
  }

  // Скрытие элементов, которые не помещаются в одну строку и их отображение при открытии
  FitListToBlock.init({
    list: 'fit-list-to-block',
    item: 'fit-list-to-block__item',
    immortal: 'fit-list-to-block__immortal',
    showOnClick: 'fit-list-to-block__show-on-click'
  })

  // Список производителей и моделей в шапке категории
  FitListToBlock.init({
    list: 'link-filter__row',
    item: 'link-filter__link',
    immortal: 'link-filter__immortal',
    showOnClick: 'link-filter__immortal',
    onChange () {
      const activeLinkInHiddenList = this.hiddenList.querySelector('.link-filter__link_active')

      if (activeLinkInHiddenList) {
        this.reset()
        this.toggleImmortals('none')
      }
    }
  })

  // Обычный список фильтров, которые идут друг за другом
  FitListToBlock.init({
    list: 'bem-filter-row__filter-list_js-one-row',
    item: 'bem-filter-row__filter',
    immortal: 'bem-filter-row__filter_immortal',
    showOnClick: 'bem-filter-row__filter_immortal',
    rowsCount: 2,
    onChange () {
      const activeLinkInHiddenList = this.hiddenList.querySelector('.bem-filter-row__filter_active')

      if (activeLinkInHiddenList) {
        this.reset()
        this.toggleImmortals('none')
      }
    }
  })

  DefferComponentInit(
    SimpleSlider,
    [
      '.row-picture-link_js-slider',
      {
        slideWidth: 'auto',
        classes: {
          slider: {
            base: 'row-slider__slider'
          },
          col: {
            base: 'row-picture-link__el'
          },
          arrow: {
            base: 'row-slider__arrow',
            active: 'row-slider__arrow_active',
            left: 'row-slider__arrow_left',
            right: 'row-slider__arrow_right'
          }
        }
      }
    ], {
      minWindowWidth: 800
    }
  )

  // таймер обратного отсчёта
  SimpleCountdown('.simple-countdown')

  // поиск на странице выбора города
  ListSearch('.city-select_js-search', {
    classes: {
      field: {
        base: 'city-select__field',
        active: 'city-select__field_active'
      },
      list: {
        active: 'city-select_search-in-progress'
      },
      listEl: {
        base: 'city-select__list-el',
        active: 'city-select__list-el_active'
      },
      listGroup: {
        base: 'city-select__col',
        active: 'city-select__col_active'
      },
      empty: {
        base: 'city-select__search-empty',
        active: 'city-select__search-empty_active'
      }
    }
  })

  // Слайдер новостей
  Siema.init('.row-slider_news', {
    selector: '.row-slider__slider',
    slideWidth: '.row-slider__product',
    wrapperStyle: {
      display: 'block'
    },
    arrows: true,
    classes: {
      prev: 'row-slider__arrow_left',
      next: 'row-slider__arrow_right',
      notEnoughSlides: 'row-slider_disable-arrows'
    }
  })

  // сетка фотографий, которые при клике на них открываются по весь экран
  PhotoswipePhotoGrid()

  // фотки на странице чейнджлога
  PhotoswipePhotoGrid('.changelog-page__el', {
    classes: {
      photo: {
        base: 'changelog-page__el-content img'
      }
    }
  })

  // фильтры в каталоге
  FilterList()

  // Lazy load для изображений, способы применения https://github.com/verlok/lazyload#recipes
  new LazyLoad({
    elements_selector: '.bem-lazy',
    threshold: 400
  })

  // тултипы для сравнения/избранного
  ToolTooltip()

  /* Вкладки на странице вакансии */
  TabManager.init({
    parentSelector: '.vacancy-page_js-content-switch',
    tab: 'vacancy-page__tab',
    wrapper: 'vacancy-page__content'
  })

  // Переключение видимости
  new Toggle({
    parent: 'toggle-visibility',
    handler: '.toggle-visibility__handler'
  })

  new Toggle({
    parent: 'js-toggle',
    handler: '.js-toggle__handler'
  })

  new Toggle({
    parent: 'bem-company-list__item',
    handler: '.bem-company-list__edit, .bem-form__cancel-button'
  })

  new Toggle({
    parent: 'custom-pc-components-list',
    handler: '.custom-pc-components-list__show-hidden-list',
    postfix: '_show-hidden'
  })

  new Toggle({
    parent: 'commentary_js-show-response-form',
    handler: '.commentary__show-response-form',
    postfix: '-active'
  })

  new Toggle({
    parent: 'catalog-root__list-el',
    handler: '.catalog-root__list-el-name'
  })

  // плашки для отображения статусов
  window.NotificationPopUp = NotificationPopUp
})
