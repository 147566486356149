/*
  Проверка вмещается ли горизонтальный список в блок при нужном кол-ве строк,
  если не влезает есть два варианта:
  1. Появляется кнопка Показать всё при клике на которую показываются скрытые пункты
  2. Список просто обрезается и скрытые пункты никак нельзя открыть,
  опционально можно добавить заглушку, например, обычную ссылку Все »
  которая будет появляться если пункты не влезли
  Конструктор принимает следующие параметры:
  list: класс списка который нужно проверять
  item: класс элемента внутри списка
  immortal: класс элементов которые появляются когда список обрезается (опционально)
  showOnClick: класс элемента при клике на который нужно показывать скрытые пункты (опционально, если указан элементы появляются при клике)
  rowsCount: максимальное кол-во строк (опционально, стандартно 1)
  rowsCountOnMobile: максимальное кол-во строк на мобильных разрешениях (опционально, стандартно 3)
  onChange: функция которая срабатывает при пересчете списка (опционально)
  rowsCountOnMobile: кол-во строк на мобильных разрешениях
  passive: не активировать фильтры автоматически при создании экземпляра, чтобы потом активировать вручную
*/

import debounce from 'lodash.debounce'
import { getWidthWithMargin } from './getWidthWithMargin'
import isMobile from './isMobile'
import fastdom from 'fastdom'
import ShareToGlobal from './ShareToGlobal'

const list = []

function FitListToBlock (opts) {
  try {
    const isAlreadyActive = !!opts.list.dataset.rowsWasActive
    if (isAlreadyActive) return

    list.push(this)
    this.list = opts.list
    this.list.dataset.rowsWasActive = true
    this.immortals = this.list.querySelectorAll(`.${opts.immortal}`)
    this.rowsCount = opts.rowsCount || parseInt(this.list.dataset.rowsCount) || 1
    this.rowsCountOnMobile = opts.rowsCountOnMobile || parseInt(this.list.dataset.rowsCountOnMobile) || 3
    this.onChange = opts.onChange
    this.itemSelector = `.${opts.item}:not(.${opts.immortal})`
    this.isPassive = opts.passive || !!this.list.dataset.rowsPassive || false
    this.previousWindowWidth = window.innerWidth

    this.render = debounce(this.render.bind(this), 300)
    this.update = this.update.bind(this)
    this.onResize = this.onResize.bind(this)

    this.showOnClick = this.list.querySelector(`.${opts.showOnClick}`)
    if (this.showOnClick) {
      this.onClickShowHiddenList = this.onClickShowHiddenList.bind(this)
      this.showOnClick.addEventListener('click', this.onClickShowHiddenList)
      this.createHiddenList()
    }

    if (!this.isPassive) fastdom.measure(() => this.update())
  } catch (e) {
    console.error(
      'Ошибка инициализации блока с функционалом скрытия внутренних пунктов',
      e
    )
  }
}

FitListToBlock.prototype.getItemsData = function (wrapper, itemSelector) {
  const items = Array.from(wrapper.querySelectorAll(itemSelector))
  const arr = []
  items.forEach((el) => {
    arr.push({
      width: getWidthWithMargin(el),
      el
    })
  })
  return arr
}

FitListToBlock.prototype.calculateProps = function () {
  this.immortalsWidth = this.getImmortalsWidth()
  this.listWidth = this.list.offsetWidth
}

FitListToBlock.prototype.getImmortalsWidth = function () {
  let width = 0
  this.immortals.forEach((item) => {
    width += getWidthWithMargin(item)
  })
  return width
}

FitListToBlock.prototype.createHiddenList = function () {
  this.hiddenList = document.createElement('div')
  this.hiddenList.style.display = 'none'
  this.list.appendChild(this.hiddenList)
}

FitListToBlock.prototype.update = function () {
  window.removeEventListener('resize', this.onResize)
  window.addEventListener('resize', this.onResize)
  this.reset()
  this.items = this.getItemsData(this.list, this.itemSelector)
  window.requestAnimationFrame(() => {
    this.calculateProps()
    this.render()
  })
}

// Проверяем, изменилась ли ширина окна при ресайзе, если нет - ничего не делаем.
// Это нужно, чтобы исключить активацию события при скрытии/появлении адрессбара в сафари
FitListToBlock.prototype.onResize = function () {
  if (this.previousWindowWidth !== window.innerWidth) {
    this.update()
    this.previousWindowWidth = window.innerWidth
  }
}

FitListToBlock.prototype.render = function () {
  let notFill = false
  let currentRow = 1
  let currentRowWidth = 0
  const maxRowsCount = isMobile() ? this.rowsCountOnMobile : this.rowsCount

  for (let i = 0; i < this.items.length; i++) {
    currentRowWidth += this.items[i].width

    if (
      currentRow === maxRowsCount && currentRowWidth > this.listWidth - this.immortalsWidth
    ) {
      fastdom.mutate(() => this.hideListItem(this.items[i].el))
      notFill = true
    } else if (currentRowWidth > this.listWidth) {
      currentRow += 1
      currentRowWidth = 0
      i -= 1
    }
  }

  this.currentRowsCount = currentRow
  notFill ? this.toggleImmortals('block') : this.toggleImmortals('none')
  this.onChange && this.onChange()
}

FitListToBlock.prototype.hideListItem = function (item) {
  if (this.showOnClick) {
    this.hiddenList.appendChild(item)
  } else {
    item.style.display = 'none'
  }
}

FitListToBlock.prototype.reset = function () {
  if (this.showOnClick) {
    this.moveItemsFromHiddenList()
    this.toggleImmortals('block')
    this.immortals.forEach((item) => {
      this.list.appendChild(item)
    })
  } else {
    this.items.forEach((item) => {
      item.style.display = 'block'
    })
  }
}

FitListToBlock.prototype.toggleImmortals = function (state) {
  fastdom.mutate(() => {
    this.immortals.forEach((item) => {
      item.style.display = state
    })
  })
}

FitListToBlock.prototype.onClickShowHiddenList = function (e) {
  this.moveItemsFromHiddenList()
  this.toggleImmortals('none')
  window.removeEventListener('resize', this.onResize)
  e.preventDefault()
}

FitListToBlock.prototype.moveItemsFromHiddenList = function () {
  while (this.hiddenList.childNodes.length > 0) {
    this.list.appendChild(this.hiddenList.childNodes[0])
  }
}

FitListToBlock.init = function (opts) {
  document.querySelectorAll(`.${opts.list}`).forEach((item) => {
    new FitListToBlock({
      list: item,
      item: opts.item,
      immortal: opts.immortal,
      showOnClick: opts.showOnClick,
      hiddenList: opts.hiddenList,
      rowsCount: opts.rowsCount,
      onChange: opts.onChange
    })
  })
}

FitListToBlock.get = function (selector) {
  const node = typeof selector === 'string' ? document.querySelector(selector) : selector
  return list.find((el) => el.list === node)
}

FitListToBlock.update = function () {
  list.forEach((el) => el.update())
}

ShareToGlobal.share('FitListToBlock', FitListToBlock)

export { FitListToBlock }
