/*
  Компонент помогает отложить инициализацию других компонентов,
  инициализирую их лишь при определенных условиях

  fn - функция, которая будет вызывана при выполнении условий
  arg - аргументы функции в виде массива
  {
    minWindowWidth - минимальная ширина окна, при которой будет вызвана функция
  }

*/

export default function DefferComponentInit (
  fn,
  arg,
  { minWindowWidth = false } = {}
) {
  if (minWindowWidth) {
    window.addEventListener('resize', onResize)
    onResize()
  }

  function onResize () {
    let isNeeded

    if (minWindowWidth) isNeeded = checkMinWindowWidth()

    if (isNeeded) {
      initFn()
      reset()
    }
  }

  function checkMinWindowWidth () {
    return window.innerWidth >= minWindowWidth
  }

  function initFn () {
    fn.apply(null, arg)
  }

  function reset () {
    window.removeEventListener('resize', onResize)
  }
}
