/* переключение видимости скрытого блока */

function Toggle (obj) {
  var arr = document.querySelectorAll('.' + obj.parent)
  for (var i = 0; i < arr.length; i++) {
    new ToggleConstructor(arr[i], obj.parent, obj.handler, obj.postfix, obj.maxWidth)
  }
}

class ToggleConstructor {
  constructor (parent, parentClass, handler, postfix = '_active', maxWidth) {
    try {
      this.parent = parent
      this.parentClass = parentClass
      this.handler = parent.querySelectorAll(handler)
      this.enableMaxWidth = maxWidth || +this.parent.dataset.enableMaxWidth
      this.postfix = postfix
      Array.from(this.handler).forEach((item) => {
        item.addEventListener('click', this.onClick.bind(this))
      })
    } catch (e) {
      console.error(e)
    }
  }

  onClick (e) {
    if (this.enableMaxWidth && this.enableMaxWidth < window.innerWidth) { return }
    this.parent.classList.toggle(this.parentClass + this.postfix)
    e.preventDefault()
  }
}

export default Toggle
