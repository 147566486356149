/* копирование чего-либо */
export default function CopyToClickboard (selector = '.copy-to-clickboard') {
  selector = typeof selector === 'string' ? document.querySelectorAll(selector) : selector
  if (selector.length > 1) {
    selector.forEach((el) => CopyToClickboard(el))
  } else if (selector.length === 0) {

  } else {
    selector = selector[0]
    init()
  }

  function init () {
    selector.addEventListener('click', onClick)
  }

  function onClick (e) {
    e.preventDefault()
    const $temp = document.createElement('input')
    $temp.value = selector.dataset.copy
    document.body.appendChild($temp)
    $temp.select()
    document.execCommand('copy')
    $temp.remove()
  }
}
