/*
  Управляющий элемент для компонентов страницы сравнения. Оркестрирует драг-н-дропы, слайдеры, галереи и т.д.
  Для каждой вкладки категории свой управляющий компонент, когда выбрана определенная вкладка - во
  всех остальных управляющие компоненты переходят в неактивное состояние и активируются лишь при выборе их вкладок.

  Объект с опциональными параметрами:
  container - родительский элемент-обёртка
  slideWidth - ширина колонки
  classes - объект с классами, структуру и стандартные параметры можно посмотреть ниже в функции getClasses
  enablePhotoswipe - включение галереи во весь экран при клике на фото
  enableRadioFilter - включение фильтра по группам
  enableColumnDrag - включение драг-н-дропа
  enableStickyHeader - включение залипающей шапки

  Внешний интерфейс экземпляра:
  id - уникальный идентификатор
  state - текущее состояние компонента, активен он или нет
  container - родительский элемент-обертка
  update - метод, который пересчитывает важные переменные, на основе которых все считается
  setState - метод, позволяющий вручную изменить состояние компонента
  afterAddNewProduct - метод, который следует вызывать после добавления новой колонки в конец списка
*/

import Sortable from '@shopify/draggable/lib/sortable.js'
import merge from 'lodash.merge'
import debounce from 'lodash.debounce'

import SimpleSlider from './SimpleSlider'
import Modal from './Modal'
import ShareToGlobal from './ShareToGlobal'
import ProductCardPhotoSwipe from './ProductCardPhotoSwipe'
import RadioFilter from './RadioFilter'
import StickySidebar from 'sticky-sidebar'

const list = []
let lastId = 1

export function CompareController ({
  container = '.compare-grid',
  slideWidth = 210,
  classes,
  enablePhotoswipe = true,
  enableRadioFilter = true,
  enableColumnDrag = true,
  enableStickyHeader = true,
  maxWidthForSelect = 800
} = {}) {
  container = typeof container === 'string' ? document.querySelector(container) : container
  if (!container) return

  // считаемые базовые параметры, размеры и переменные
  let sliders
  let photoSlider
  let specSlider
  let navigationEnable
  let stickyHeader
  let state
  let whichSelectWasSelected
  let isRecommendedExist = false
  let leftNavigation
  let rightNavigation
  let radioFilter
  let windowWidth
  let dragInterval
  let mousePositionDetect
  let mouseX
  let select

  const id = lastId
  lastId += 1
  container.dataset.compareId = id

  classes = getClasses(classes)
  const dragColSelector = `.${classes.col.base}:not(.${classes.col.recommended}):not(.${classes.col.add})`

  window.addEventListener('resize', debounce(onResize, 400))
  init()
  initNavigation()
  initRecommendedCol()
  initSelect()
  log()

  if (enableRadioFilter) radioFilter = initRadioFilter()
  if (enableColumnDrag) initDraggable()
  if (enableStickyHeader) initStickyHeader()
  if (enablePhotoswipe) ProductCardPhotoSwipe(container)

  function getClasses (obj) {
    return merge({
      slider: {
        base: 'compare-grid__slider',
        container: 'compare-grid__cols'
      },
      row: {
        base: 'compare-grid__row',
        photo: 'compare-grid__row_product-photo'
      },
      col: {
        base: 'compare-grid__col',
        recommended: 'compare-grid__col_recommended',
        dragMirror: 'compare-grid__col_mirror',
        add: 'compare-grid__col_add'
      },
      navigation: {
        active: 'compare-grid__navigation_active',
        left: 'compare-grid__navigation_left',
        right: 'compare-grid__navigation_right'
      },
      drag: {
        handle: 'product-card__photo-icon_move'
      }
    },
    obj
    )
  }

  // После добавления новго продукта, нужно вызывать эту функцию. Новый продукт
  // нужно добавлять в самый конец, последней колонкой
  function afterAddNewProduct () {
    update()
    // если расширение мобильное - переносим последнюю колонку в начало
    if (windowWidth <= 800) {
      sliders.forEach((el) => el.columnSwap(photoSlider.getColCount() - 1, whichSelectWasSelected))
    } else {
      // если десктопное - последнюю колонку перед рекомендованной
      const swapTo = isRecommendedExist ? photoSlider.getColCount() - 3 : photoSlider.getColCount() - 2
      sliders.forEach((el) => el.columnInsert(photoSlider.getColCount() - 1, swapTo))
    }
    updateSelect()
  }

  function onResize () {
    if (state === false) return
    update()
    // если колонку рекомендованных перемещали - ставим её на место т.к. в десктопе она занимает фиксированное место
    if (isRecommendedExist) {
      const col = photoSlider.getColByClass(classes.col.recommended)
      const index = photoSlider.getIndexOfCol(col)
      if (index !== photoSlider.getColCount() - 2) {
        sliders.forEach((el) => el.columnSwap(index, photoSlider.getColCount() - 2))
      }
    }

    updateSelect()
  }

  function setState (st) {
    st ? enable() : disable()
    state = st
    console.log(`Состояние контейнера с id ${id} изменено на: ${state}`)
  }

  function enable () {
    update()
    initStickyHeader()
  }

  function disable () {
    stickyHeader.destroy()
  }

  function calc () {
    windowWidth = window.innerWidth
    if (windowWidth <= 800 && radioFilter) {
      radioFilter.reset({
        resetFilter: true
      })
    }
  }

  function init () {
    calc()
    state = true
    sliders = Array.from(container.querySelectorAll(`.${classes.slider.container}`))
    sliders = sliders.map((el) =>
      SimpleSlider(el, {
        classes: {
          slider: {
            base: classes.slider.base
          },
          col: {
            base: classes.col.base
          }
        },
        slideWidth,
        enableResizeHandler: false,
        navigation: false
      })
    )
    photoSlider = sliders[0]
    specSlider = sliders.slice(1)
  }

  function update () {
    if (state === false) return

    calc()
    sliders.forEach((el) => el.update())
    navigationCheck()
  }

  function initRadioFilter () {
    return RadioFilter(container.closest('.compare-page__content'), {
      classes: {
        filter: {
          container: 'sort-row'
        },
        content: {
          base: 'compare-grid__group',
          hide: 'compare-grid__group_hide'
        }
      }
    })
  }

  function initNavigation () {
    leftNavigation = container.querySelectorAll(`.${classes.navigation.left}`)
    rightNavigation = container.querySelectorAll(`.${classes.navigation.right}`)
    leftNavigation.forEach((el) => el.addEventListener('click', toLeft.bind(null, 1)))
    rightNavigation.forEach((el) => el.addEventListener('click', toRight.bind(null, 1)))
    navigationCheck()
  }

  function toggleNavigation (leftState, rightState) {
    leftNavigation.forEach((e) => e.classList[leftState ? 'add' : 'remove'](classes.navigation.active))
    rightNavigation.forEach((e) => e.classList[rightState ? 'add' : 'remove'](classes.navigation.active))
  }

  function navigationCheck () {
    console.log(photoSlider.getMaxOffset())
    navigationEnable = !(photoSlider.getMaxOffset() >= 0)
    if (!navigationEnable) {
      toggleNavigation(false, false)
    } else if (photoSlider.getCurrentOffset() === 0) {
      toggleNavigation(false, true)
    } else if (photoSlider.getCurrentOffset() <= photoSlider.getMaxOffset()) {
      toggleNavigation(true, false)
    } else {
      toggleNavigation(true, true)
    }
  }

  function toLeft (count = 1) {
    sliders.forEach((el) => el.toLeft(count))
    navigationCheck()
    log()
  }

  function toRight (count = 1) {
    sliders.forEach((el) => el.toRight(count))
    navigationCheck()
    log()
  }

  function initDraggable () {
    // внутри библиотеки есть вычисление размеров
    const sortable = new Sortable(photoSlider.slider, {
      draggable: dragColSelector,
      handle: `.${classes.drag.handle}`,
      mirror: {
        yAxis: false,
        appendTo: container.querySelector(`.${classes.row.photo}`)
      },
      classes: {
        mirror: classes.col.dragMirror
      }
    })
    sortable.on('drag:start', onDragStart)
    sortable.on('drag:stop', onDragStop)
    sortable.on('sortable:sorted', afterSort)
  }

  function onDragStart () {
    mousePositionDetect = window.addEventListener('mousemove', updateMousePosition)
    dragInterval = setInterval(onDragMove, 40)
  }

  function updateMousePosition (e) {
    mouseX = e.pageX
  }

  function onDragStop () {
    window.removeEventListener('mousemove', mousePositionDetect)
    clearInterval(dragInterval)
  }

  function onDragMove () {
    if (mouseX < 40) {
      // если пользователь переместил курсор мыши при переносе к левой части экрана
      toLeft(0.05)
    } else if (mouseX > windowWidth - 40) {
      // если к правой
      toRight(0.05)
    }
  }

  function afterSort (e) {
    if (e.oldIndex !== e.newIndex) specSlider.forEach((el) => el.columnInsert(e.oldIndex, e.newIndex))
  }

  function initStickyHeader () {
    // внутри библиотеки StickySidebar есть вычисление размеров
    stickyHeader = new StickySidebar(container.querySelector('.compare-grid__below-photo-wrapper'), {
      minWidth: 800,
      containerSelector: '.compare-grid__below-photo',
      innerWrapperSelector: '.compare-grid__row_product-text',
      stickyClass: 'compare-grid__below-photo_active',
      resizeSensor: false
    })
  }

  function initRecommendedCol () {
    const recommended = photoSlider.getColByClass(classes.col.recommended)
    if (recommended) {
      const index = photoSlider.getIndexOfCol(recommended)
      sliders.forEach((el) => el.addClassForColByIndex(classes.col.recommended, index))
      isRecommendedExist = true
    }
  }

  // селект для телефонов вместо навигации через стрелки
  function initSelect () {
    select = Array.from(container.parentNode.querySelectorAll('.compare-page__select'))
    updateSelect()
    select.forEach((el) => el.addEventListener('change', onSelectChange))
  }

  function updateSelect () {
    if (windowWidth >= maxWidthForSelect) return

    const productList = []
    specSlider[0].getCols().forEach((el, index) => {
      let title
      if (el.classList.contains(classes.col.recommended)) {
        title = `Рекомендуем аналог: ${el.querySelector('.product-card__title a').innerText}`
      } else if (el.querySelector('.product-card__title a')) {
        title = el.querySelector('.product-card__title a').innerText
      } else {
        title = el.querySelector('.compare-grid__col-title').innerText
      }

      let value
      if (el.classList.contains(classes.col.add)) {
        value = 'add'
      } else {
        value = index
      }
      productList.push({
        value,
        title
      })
    })

    select.forEach((el, index) => {
      el.innerHTML = ''
      productList.forEach((e, i) => {
        const option = document.createElement('option')
        option.value = e.value
        option.innerText = e.title
        if (index === 0 && i === 0) {
          option.selected = true
        } else if (index === 1 && i === 1) {
          option.selected = true
        }
        el.appendChild(option)
      })
    })
  }

  function onSelectChange (e) {
    whichSelectWasSelected = +this.dataset.compareSelect
    console.log(`Был выбран селект с id: ${whichSelectWasSelected}`)
    if (this.value === 'add') {
      const modal = Modal.getModal('.compare-grid__col_add')
      modal.showModal()
    } else {
      sliders.forEach((el) => el.columnSwap(+this.value, +this.dataset.compareSelect))
      updateSelect()
    }
  }

  function api () {
    return {
      id,
      state,
      container,
      init,
      update,
      setState,
      afterAddNewProduct,
      onResize
    }
  }

  function log () {
    console.log('Информация для дебага страницы сравнения:', {
      id,
      state,
      navigationEnable
    })
    photoSlider.log()
  }

  list.push(api())
  return api()
}

/*
  функция для получения инициализированного экземпляра конструктора по селектору родительского элемента.
  Полезна для получения экземпляра сравнения внутри определенной вкладки категории
*/
export function getCompare (selector) {
  const target = typeof selector === 'string' ? document.querySelector(selector) : selector
  return list.find((el) => el.container === target)
}

/*
  переключение всех активированных экземпляров в определенное состояние, также можно передать массив
  reverse в опциональном объекте. Для элементов этого массива будет применяться противоположное состояние
*/
export function toggleCompare (state, {
  reverse = []
} = {}) {
  list.forEach((el) => {
    reverse.length > 0 && reverse.some((x) => x === el.container) ? el.setState(!state) : el.setState(state)
    el.update()
  })
}

ShareToGlobal.share('CompareController', CompareController)
ShareToGlobal.share('getCompare', getCompare)
