import fastdom from 'fastdom'
import merge from 'lodash.merge'
import debounce from 'lodash.debounce'
import anime from 'animejs'

/**
 * Hi :-) This is a class representing a Siema.
 */
export default class Siema {
  /**
   * Create a Siema.
   * @param {Object} options - Optional settings object.
   */
  constructor (slider, options) {
    try {
      this.$slider = typeof slider === 'string' ? document.querySelector(slider) : slider
      if (!this.$slider) throw Error('Can\'t find slider selector')

      // if the slider is already active then skip an initialization
      if (this.$slider.dataset.siemaIsActive) return

      this.config = Siema.mergeSettings(options)
      if (this.config.cloneCount % 2 !== 0) throw Error('the cloneCount must be even');

      // Bind all event handlers for referencability
      [
        'resizeHandler',
        'touchstartHandler',
        'touchendHandler',
        'touchmoveHandler',
        'mousedownHandler',
        'mouseupHandler',
        'mouseleaveHandler',
        'mousemoveHandler',
        'clickHandler'
      ].forEach((method) => {
        this[method] = this[method].bind(this)
      })

      this.$slider.dataset.siemaIsActive = true
      // Build markup and apply required styling to elements
      fastdom.measure(this.init, this)
    } catch (e) {
      console.error(e.message || 'Error on slider initialization', e)
    }
  }

  /**
   * Overrides default settings with custom ones.
   * @param {Object} options - Optional settings object.
   * @returns {Object} - Custom Siema settings.
   */
  static mergeSettings (options) {
    return merge({
      autoplay: false,
      markCurrent: false,
      arrows: false,
      arrowSlideThrough: 1,
      asideSlideClickNav: false,
      wrapperStyle: {},
      cloneCount: 0,
      slideWidth: false,
      selector: '.siema',
      duration: 200,
      easing: 'ease-out',
      perPage: 1,
      startIndex: 0,
      draggable: true,
      multipleDrag: true,
      threshold: 20,
      loop: false,
      rtl: false,
      checkSlidesFill: true,
      classes: {
        active: 'slider-base__slide_is-active',
        prev: 'slider-base__left-arrow',
        next: 'slider-base__right-arrow',
        notEnoughSlides: 'slider-base_not-enough-slides',
        onDrag: 'slider-base_drag'
      },
      onInit: () => {},
      onChange: () => {},
      onClick: () => {}
    }, options)
  }

  /**
   * Determine if browser supports unprefixed transform property.
   * Google Chrome since version 26 supports prefix-less transform
   * @returns {string} - Transform property supported by client.
   */
  static webkitOrNot () {
    const style = document.documentElement.style
    if (typeof style.transform === 'string') {
      return 'transform'
    }
    return 'WebkitTransform'
  }

  calculateImportantVariables () {
    if (this.config.slideWidth) {
      this.slideWidth = this.selector.querySelector(this.config.slideWidth).offsetWidth
      this.selectorWidth = this.selector.offsetWidth
    } else {
      this.selectorWidth = this.selector.offsetWidth
      this.slideWidth = this.selectorWidth / this.perPage
    }
    this.maxIndexInNoneLoop = this.innerElements.length - Math.floor(this.selectorWidth / this.slideWidth)
  }

  attachEvents () {
    // Resize element on window resize
    window.addEventListener('resize', debounce(this.resizeHandler, 400))

    // If element is draggable / swipable, add event handlers
    if (this.config.draggable) {
      // Keep track pointer hold and dragging distance
      this.pointerDown = false
      this.drag = {
        startX: 0,
        endX: 0,
        startY: 0,
        letItGo: null,
        preventClick: false
      }

      // Touch events
      this.selector.addEventListener('touchstart', this.touchstartHandler, {
        passive: true
      })
      this.selector.addEventListener('touchend', this.touchendHandler, {
        passive: true
      })
      this.selector.addEventListener('touchmove', this.touchmoveHandler, {
        passive: true
      })

      // Mouse events
      this.selector.addEventListener('mousedown', this.mousedownHandler)
      this.selector.addEventListener('mouseup', this.mouseupHandler)
      this.selector.addEventListener('mouseleave', this.mouseleaveHandler)
      this.selector.addEventListener('mousemove', this.mousemoveHandler)

      // Click
      this.selector.addEventListener('click', this.clickHandler)
    }
  }

  detachEvents () {
    window.removeEventListener('resize', this.resizeHandler)
    this.selector.removeEventListener('touchstart', this.touchstartHandler)
    this.selector.removeEventListener('touchend', this.touchendHandler)
    this.selector.removeEventListener('touchmove', this.touchmoveHandler)
    this.selector.removeEventListener('mousedown', this.mousedownHandler)
    this.selector.removeEventListener('mouseup', this.mouseupHandler)
    this.selector.removeEventListener('mouseleave', this.mouseleaveHandler)
    this.selector.removeEventListener('mousemove', this.mousemoveHandler)
    this.selector.removeEventListener('click', this.clickHandler)
  }

  /**
   * Builds the markup and attaches listeners to required events.
   */
  init () {
    this.selector =
      typeof this.config.selector === 'string'
        ? this.$slider.querySelector(this.config.selector)
        : this.config.selector
    if (this.selector === null) throw new Error('Something wrong with your selector 😭')

    this.pipes = {}
    if (this.config.asideSlideClickNav) this.appendToEventPipe(['click'], this.asideSlideNav)
    if (this.config.onClick) this.appendToEventPipe(['click'], this.config.onClick)
    this.config.onClick = this.pipeExecution('click')

    if (this.config.onChange) this.appendToEventPipe(['change'], this.config.onChange)
    this.config.onChange = this.pipeExecution('change')

    if (this.config.onInit) this.appendToEventPipe(['init'], this.config.onInit)
    this.config.onInit = this.pipeExecution('init')

    if (this.config.arrows) this.initArrows()
    if (this.config.autoplay || !!this.$slider.dataset.siemaAutoplay) this.initAutoplay()

    this.innerElements = [].slice.call(this.selector.children)

    this.resolveSlidesNumber()
    this.calculateImportantVariables()
    if (this.config.checkSlidesFill && this.isNotEnoughSlides()) return

    this.currentSlide = this.config.loop
      ? this.config.startIndex % this.innerElements.length
      : Math.max(0, Math.min(this.config.startIndex, this.innerElements.length - this.perPage))

    this.transformProperty = Siema.webkitOrNot()
    fastdom.mutate(this.changeSelectorStyles, this)
    fastdom.mutate(this.buildSliderFrame, this)

    this.attachEvents()
    this.config.onInit.call(this)
  }

  initAutoplay () {
    if (this.config.autoplay) {
      this.timer = window.setInterval(this.next.bind(this), this.config.autoplay)
    } else if (this.$slider.dataset.siemaAutoplay) {
      this.timer = window.setInterval(this.next.bind(this), +this.$slider.dataset.siemaAutoplay)
    }
  }

  clearAutoplay () {
    if (this.timer) window.clearInterval(this.timer)
  }

  changeSelectorStyles () {
    // hide everything out of selector's boundaries
    this.selector.style.overflow = 'hidden'
    this.selector.style.direction = this.config.rtl ? 'rtl' : 'ltr'
    if (this.config.draggable) this.selector.style.cursor = '-webkit-grab'

    // allow add an additional styles for a slider selector
    for (const prop in this.config.wrapperStyle) {
      this.selector.style[prop] = this.config.wrapperStyle[prop]
    }
  }

  appendToEventPipe (pipeNames = [], fn) {
    pipeNames.forEach((pipe) => {
      this.pipes[pipe] ? this.pipes[pipe].push(fn.bind(this)) : (this.pipes[pipe] = [fn.bind(this)])
    })
  }

  pipeExecution (pipeName) {
    return function (e) {
      if (this.pipes[pipeName]) {
        this.pipes[pipeName].forEach((pipe) => {
          pipe(e)
        })
      }
    }
  }

  /**
   * Build a sliderFrame and slide to a current item.
   */
  buildSliderFrame () {
    const itemsToBuild = this.config.loop
      ? this.innerElements.length + 2 * this.perPage + this.config.cloneCount
      : this.innerElements.length

    // Create frame and apply styling
    this.sliderFrame = document.createElement('div')

    this.sliderFrameWidth = this.slideWidth * itemsToBuild
    this.sliderFrame.style.width = `${this.sliderFrameWidth}px`
    this.minFrameOffset = (this.sliderFrameWidth - this.selectorWidth) * -1
    this.enableTransition()

    // Create a document fragment to put slides into it
    const docFragment = document.createDocumentFragment()

    // Loop through the slides, add styling and add them to document fragment
    if (this.config.loop) {
      for (
        let i = this.innerElements.length - this.perPage - this.config.cloneCount / 2; i < this.innerElements.length; i++
      ) {
        const element = this.buildSliderFrameItem(this.innerElements[i].cloneNode(true), i)
        docFragment.appendChild(element)
      }
    }
    for (let i = 0; i < this.innerElements.length; i++) {
      const element = this.buildSliderFrameItem(this.innerElements[i], i)
      docFragment.appendChild(element)
    }
    if (this.config.loop) {
      for (let i = 0; i < this.perPage + this.config.cloneCount / 2; i++) {
        const element = this.buildSliderFrameItem(this.innerElements[i].cloneNode(true), i)
        docFragment.appendChild(element)
      }
    }

    // Add fragment to the frame
    this.sliderFrame.appendChild(docFragment)

    // Clear selector (just in case something is there) and insert a frame
    this.selector.innerHTML = ''
    this.selector.appendChild(this.sliderFrame)

    // Go to currently active slide after initial build
    this.slideToCurrent()
  }

  // проверка, нужен ли вообще слайдер и выходят ли слайды за область своего родителя
  isNotEnoughSlides () {
    if (this.innerElements.length * this.slideWidth > this.selectorWidth) {
      this.$slider.classList.remove(this.config.classes.notEnoughSlides)
      return false
    } else {
      this.$slider.classList.add(this.config.classes.notEnoughSlides)
      return true
    }
  }

  buildSliderFrameItem (elm, index) {
    const elementContainer = document.createElement('div')
    elementContainer.dataset.slideIndex = index
    elementContainer.style.cssFloat = this.config.rtl ? 'right' : 'left'
    elementContainer.style.float = this.config.rtl ? 'right' : 'left'

    elementContainer.style.width = this.getSliderFrameItemWidth()
    elementContainer.appendChild(elm)
    return elementContainer
  }

  getSliderFrameItemWidth () {
    if (this.config.slideWidth) return `${this.slideWidth}px`
    if (this.config.loop) return `${100 / (this.innerElements.length + this.perPage * 2 + this.config.cloneCount)}%`
    return `${100 / this.innerElements.length}%`
  }

  /**
   * Determinates slides number accordingly to clients viewport.
   */
  resolveSlidesNumber () {
    if (typeof this.config.perPage === 'number') {
      this.perPage = this.config.perPage
    } else if (typeof this.config.perPage === 'object') {
      this.perPage = 1
      for (const viewport in this.config.perPage) {
        if (window.innerWidth >= viewport) {
          this.perPage = this.config.perPage[viewport]
        }
      }
    }
  }

  markCurrent () {
    if (!this.$currentSlide) this.$currentSlide = this.innerElements[0]
    this.$currentSlide.classList.remove(this.config.classes.active)
    this.$currentSlide = this.innerElements[this.currentSlide]
    this.$currentSlide.classList.add(this.config.classes.active)
  }

  initArrows () {
    this.$slider
      .querySelector(`.${this.config.classes.prev}`)
      .addEventListener('click', this.onClickLeftArrow.bind(this))
    this.$slider
      .querySelector(`.${this.config.classes.next}`)
      .addEventListener('click', this.onClicRightArrow.bind(this))
  }

  onClickLeftArrow () {
    if (this.config.loop) {
      this.prev(this.config.arrowSlideThrough)
    } else if (this.currentSlide !== 0) {
      this.prev(this.config.arrowSlideThrough)
    } else {
      anime({
        targets: this.sliderFrame,
        translateX: [100, 0]
      })
    }
  }

  onClicRightArrow () {
    if (this.config.loop) {
      this.next(this.config.arrowSlideThrough)
    } else if (this.currentSlide !== this.maxIndexInNoneLoop) {
      this.next(this.config.arrowSlideThrough)
    } else {
      anime({
        targets: this.sliderFrame,
        translateX: [-100, 0]
      })
    }
  }

  asideSlideNav (e) {
    if (this.transitionState) {
      e.preventDefault()
      return
    }
    this.clearAutoplay()
    const $slideWrapper = e.target.closest('div[data-slide-index]')
    if (!$slideWrapper) return
    const index = parseInt($slideWrapper.dataset.slideIndex)
    if (index !== this.currentSlide) {
      e.preventDefault()
      if (
        (index === this.innerElements.length - 1 && this.currentSlide === index - 1) ||
        (index === 0 && this.currentSlide === this.innerElements.length - 1)
      ) {
        this.next()
      } else if (
        (index === this.innerElements.length - 1 && this.currentSlide === 0) ||
        (index === 0 && this.currentSlide === 1)
      ) {
        this.prev()
      } else if (index > this.currentSlide) {
        this.next()
      } else if (index < this.currentSlide) {
        this.prev()
      }
    }
  }

  /**
   * Go to previous slide.
   * @param {number} [howManySlides=1] - How many items to slide backward.
   * @param {function} callback - Optional callback function.
   */
  prev (howManySlides = 1, callback) {
    // early return when there is nothing to slide
    if (this.innerElements.length <= this.perPage) {
      return
    }

    const beforeChange = this.currentSlide

    if (this.config.loop) {
      const isNewIndexClone = this.currentSlide - howManySlides < 0
      if (isNewIndexClone) {
        this.disableTransition()
        const mirrorSlideIndex = this.currentSlide + this.innerElements.length
        const mirrorSlideIndexOffset = this.perPage + this.config.cloneCount / 2
        const moveTo = mirrorSlideIndex + mirrorSlideIndexOffset
        const offset = this.config.slideWidth
          ? (this.config.rtl ? 1 : -1) * moveTo * this.slideWidth
          : (this.config.rtl ? 1 : -1) * moveTo * (this.selectorWidth / this.perPage)
        const dragDistance = this.config.draggable ? this.drag.endX - this.drag.startX : 0
        this.sliderFrame.style[this.transformProperty] = `translate3d(${offset + dragDistance}px, 0, 0)`
        this.currentSlide = mirrorSlideIndex - howManySlides
      } else {
        this.currentSlide = this.currentSlide - howManySlides
      }
    } else {
      this.currentSlide = Math.max(this.currentSlide - howManySlides, 0)
    }

    if (beforeChange !== this.currentSlide) {
      this.slideToCurrent(this.config.loop)
      this.config.onChange.call(this)
      if (callback) {
        callback.call(this)
      }
    }
  }

  /**
   * Go to next slide.
   * @param {number} [howManySlides=1] - How many items to slide forward.
   * @param {function} callback - Optional callback function.
   */
  next (howManySlides = 1, callback) {
    // early return when there is nothing to slide
    if (this.innerElements.length <= this.perPage) return

    const beforeChange = this.currentSlide

    if (this.config.loop) {
      const isNewIndexClone = this.currentSlide + howManySlides > this.innerElements.length - this.perPage
      if (isNewIndexClone) {
        this.disableTransition()

        const mirrorSlideIndex = this.currentSlide - this.innerElements.length
        const mirrorSlideIndexOffset = this.perPage + this.config.cloneCount / 2
        const moveTo = mirrorSlideIndex + mirrorSlideIndexOffset
        const offset = this.config.slideWidth
          ? (this.config.rtl ? 1 : -1) * moveTo * this.slideWidth
          : (this.config.rtl ? 1 : -1) * moveTo * (this.selectorWidth / this.perPage)
        const dragDistance = this.config.draggable ? this.drag.endX - this.drag.startX : 0

        this.sliderFrame.style[this.transformProperty] = `translate3d(${offset + dragDistance}px, 0, 0)`
        this.currentSlide = mirrorSlideIndex + howManySlides
      } else {
        this.currentSlide = this.currentSlide + howManySlides
      }
    } else {
      this.currentSlide = Math.min(this.currentSlide + howManySlides, this.innerElements.length - this.perPage)
    }

    if (beforeChange !== this.currentSlide) {
      this.slideToCurrent(this.config.loop)
      this.config.onChange.call(this)
      if (callback) {
        callback.call(this)
      }
    }
  }

  /**
   * Disable transition on sliderFrame.
   */
  disableTransition () {
    this.sliderFrame.style.webkitTransition = `all 0ms ${this.config.easing}`
    this.sliderFrame.style.transition = `all 0ms ${this.config.easing}`
  }

  /**
   * Enable transition on sliderFrame.
   */
  enableTransition () {
    this.sliderFrame.style.webkitTransition = `all ${this.config.duration}ms ${this.config.easing}`
    this.sliderFrame.style.transition = `all ${this.config.duration}ms ${this.config.easing}`
  }

  /**
   * Go to slide with particular index
   * @param {number} index - Item index to slide to.
   * @param {function} callback - Optional callback function.
   */
  goTo (index, {
    callback,
    animation,
    enableTransition = false
  } = {}) {
    if (this.innerElements.length <= this.perPage) {
      return
    }
    const beforeChange = this.currentSlide
    this.currentSlide = this.config.loop
      ? index % this.innerElements.length
      : Math.min(Math.max(index, 0), this.innerElements.length - this.perPage)
    if (beforeChange !== this.currentSlide) {
      this.slideToCurrent(enableTransition, {
        animation
      })
      this.config.onChange.call(this)
      if (callback) {
        callback.call(this)
      }
    }
  }

  /**
   * Moves sliders frame to position of currently active slide
   */
  slideToCurrent (enableTransition, {
    animation = true
  } = {}) {
    const currentSlide = this.config.loop
      ? this.currentSlide + this.perPage + this.config.cloneCount / 2
      : this.currentSlide

    let offset = this.config.slideWidth
      ? (this.config.rtl ? 1 : -1) * currentSlide * this.slideWidth
      : (this.config.rtl ? 1 : -1) * currentSlide * (this.selectorWidth / this.perPage)

    if (!this.config.loop && offset < this.minFrameOffset) {
      offset = this.minFrameOffset
      this.currentSlide = this.maxIndexInNoneLoop
    }

    if (this.config.markCurrent) {
      if (!this.$currentSlide) this.$currentSlide = this.innerElements[this.config.startIndex]
      this.$currentSlide.classList.remove(this.config.classes.active)
      this.$currentSlide = this.innerElements[this.currentSlide]
      this.$currentSlide.classList.add(this.config.classes.active)
    }

    if (enableTransition) {
      // This one is tricky, I know but this is a perfect explanation:
      // https://youtu.be/cCOL7MC4Pl0
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          this.enableTransition()
          this.transitionLock()
          this.sliderFrame.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`
        })
      })
    } else {
      if (animation) {
        this.sliderFrame.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`
      } else {
        // браузер пытается объединить изменения стилей в одну отрисовку фрейма,
        // но нам нужно разделить изменения стилей по разным фреймам, что бы
        // произвести смену слайда без анимации
        this.disableTransition()
        window.requestAnimationFrame(() => {
          this.sliderFrame.style[this.transformProperty] = `translate3d(${offset}px, 0, 0)`
          window.requestAnimationFrame(() => {
            this.enableTransition()
          })
        })
      }
    }
  }

  // transition state indicator whils it is active
  transitionLock () {
    this.transitionState = true
    setTimeout(() => {
      this.transitionState = false
    }, this.config.duration)
  }

  /**
   * Recalculate drag /swipe event and reposition the frame of a slider
   */
  updateAfterDrag () {
    const movement = (this.config.rtl ? -1 : 1) * (this.drag.endX - this.drag.startX)
    const movementDistance = Math.abs(movement)
    const howManySliderToSlide = this.updateAfterDragHowManySlides(movementDistance)

    const slideToNegativeClone = movement > 0 && this.currentSlide - howManySliderToSlide < 0
    const slideToPositiveClone =
      movement < 0 && this.currentSlide + howManySliderToSlide > this.innerElements.length - this.perPage

    if (movement > 0 && movementDistance > this.config.threshold && this.innerElements.length > this.perPage) {
      this.prev(howManySliderToSlide)
    } else if (movement < 0 && movementDistance > this.config.threshold && this.innerElements.length > this.perPage) {
      this.next(howManySliderToSlide)
    }
    this.slideToCurrent(slideToNegativeClone || slideToPositiveClone)
  }

  updateAfterDragHowManySlides (movementDistance) {
    if (this.config.multipleDrag) {
      return this.config.slideWidth
        ? Math.ceil(movementDistance / this.slideWidth)
        : Math.ceil(movementDistance / (this.selectorWidth / this.perPage))
    }
    return 1
  }

  /**
   * When window resizes, resize slider components as well
   */
  resizeHandler () {
    // update perPage number dependable of user value
    fastdom.measure(() => {
      this.resolveSlidesNumber()
      this.calculateImportantVariables()

      // relcalculate currentSlide
      // prevent hiding items when browser width increases
      if (this.currentSlide + this.perPage > this.innerElements.length) {
        this.currentSlide = this.innerElements.length <= this.perPage ? 0 : this.innerElements.length - this.perPage
      }
    }, this)

    fastdom.mutate(this.buildSliderFrame, this)
  }

  /**
   * Clear drag after touchend and mouseup event
   */
  clearDrag () {
    this.drag = {
      startX: 0,
      endX: 0,
      startY: 0,
      letItGo: null,
      preventClick: this.drag.preventClick
    }

    // убираем класс в следующем тике, для того что бы можно было считать событие isDragActive в этом
    window.requestAnimationFrame(() => {
      this.$slider.classList.remove(this.config.classes.onDrag)
    })
  }

  /**
   * touchstart event handler
   */
  touchstartHandler (e) {
    this.clearAutoplay()
    // Prevent dragging / swiping on inputs, selects and textareas
    const ignoreSiema = ['TEXTAREA', 'OPTION', 'INPUT', 'SELECT'].indexOf(e.target.nodeName) !== -1
    if (ignoreSiema) {
      return
    }

    e.stopPropagation()
    this.pointerDown = true
    this.drag.startX = e.touches[0].pageX
    this.drag.startY = e.touches[0].pageY
  }

  /**
   * touchend event handler
   */
  touchendHandler (e) {
    e.stopPropagation()
    this.pointerDown = false
    this.enableTransition()
    if (this.drag.endX) {
      this.updateAfterDrag()
    }
    this.clearDrag()
  }

  /**
   * touchmove event handler
   */
  touchmoveHandler (e) {
    e.stopPropagation()

    if (this.drag.letItGo === null) {
      this.drag.letItGo =
        Math.abs(this.drag.startY - e.touches[0].pageY) < Math.abs(this.drag.startX - e.touches[0].pageX)
    }

    if (this.pointerDown && this.drag.letItGo) {
      e.preventDefault()
      this.drag.endX = e.touches[0].pageX
      this.moveHandler()
    }
  }

  /**
   * mousedown event handler
   */
  mousedownHandler (e) {
    this.clearAutoplay()
    // Prevent dragging / swiping on inputs, selects and textareas
    const ignoreSiema = ['TEXTAREA', 'OPTION', 'INPUT', 'SELECT'].indexOf(e.target.nodeName) !== -1
    if (ignoreSiema) return

    e.preventDefault()
    e.stopPropagation()
    this.pointerDown = true
    this.drag.startX = e.pageX
  }

  /**
   * mouseup event handler
   */
  mouseupHandler (e) {
    // e.stopPropagation();
    this.pointerDown = false
    this.selector.style.cursor = '-webkit-grab'
    this.enableTransition()
    if (this.drag.endX) {
      this.updateAfterDrag()
    }
    this.clearDrag()
  }

  /**
   * mousemove event handler
   */
  mousemoveHandler (e) {
    e.preventDefault()
    if (this.pointerDown) {
      // if dragged element is a link
      // mark preventClick prop as a true
      // to detemine about browser redirection later on
      if (e.target.nodeName === 'A' || e.target.parentNode.nodeName === 'A') this.drag.preventClick = true
      this.drag.endX = e.pageX
      this.selector.style.cursor = '-webkit-grabbing'
      this.moveHandler()
    }
  }

  moveHandler () {
    this.disableTransition()
    this.$slider.classList.add(this.config.classes.onDrag)

    const currentSlide = this.config.loop
      ? this.currentSlide + this.perPage + this.config.cloneCount / 2
      : this.currentSlide

    const frameOffset = this.getFrameOffset(currentSlide)
    const dragOffset = this.drag.endX - this.drag.startX
    const offset = this.config.rtl ? frameOffset + dragOffset : frameOffset - dragOffset
    this.sliderFrame.style[this.transformProperty] = `translate3d(${(this.config.rtl ? 1 : -1) * offset}px, 0, 0)`
  }

  getFrameOffset (currentSlide) {
    if (this.config.slideWidth) {
      if (!this.config.loop && currentSlide * this.slideWidth > Math.abs(this.minFrameOffset)) {
        const diff = Math.abs(this.minFrameOffset) % this.slideWidth
        return (currentSlide - 1) * this.slideWidth + diff
      }
      return currentSlide * this.slideWidth
    }
    return currentSlide * (this.selectorWidth / this.perPage)
  }

  /**
   * mouseleave event handler
   */
  mouseleaveHandler (e) {
    if (this.pointerDown) {
      this.pointerDown = false
      this.$slider.classList.remove(this.config.classes.onDrag)
      this.selector.style.cursor = '-webkit-grab'
      this.drag.endX = e.pageX
      this.drag.preventClick = false
      this.enableTransition()
      this.updateAfterDrag()
      this.clearDrag()
    }
  }

  /**
   * click event handler
   */
  clickHandler (e) {
    // if the dragged element is a link
    // prevent browsers from folowing the link

    if (this.drag.preventClick) {
      e.preventDefault()
    } else if (e.type === 'click') {
      this.config.onClick.call(this, e)
    }
    this.drag.preventClick = false
  }

  /**
   * Remove item from carousel.
   * @param {number} index - Item index to remove.
   * @param {function} callback - Optional callback to call after remove.
   */
  remove (index, callback) {
    if (index < 0 || index >= this.innerElements.length) {
      throw new Error('Item to remove doesn\'t exist 😭')
    }

    // Shift sliderFrame back by one item when:
    // 1. Item with lower index than currenSlide is removed.
    // 2. Last item is removed.
    const lowerIndex = index < this.currentSlide
    const lastItem = this.currentSlide + this.perPage - 1 === index

    if (lowerIndex || lastItem) {
      this.currentSlide--
    }

    this.innerElements.splice(index, 1)

    // build a frame and slide to a currentSlide
    this.buildSliderFrame()

    if (callback) {
      callback.call(this)
    }
  }

  /**
   * Insert item to carousel at particular index.
   * @param {HTMLElement} item - Item to insert.
   * @param {number} index - Index of new new item insertion.
   * @param {function} callback - Optional callback to call after insert.
   */
  insert (item, index, callback) {
    if (index < 0 || index > this.innerElements.length + 1) {
      throw new Error('Unable to inset it at this index 😭')
    }
    if (this.innerElements.indexOf(item) !== -1) {
      throw new Error('The same item in a carousel? Really? Nope 😭')
    }

    // Avoid shifting content
    const shouldItShift = index <= this.currentSlide > 0 && this.innerElements.length
    this.currentSlide = shouldItShift ? this.currentSlide + 1 : this.currentSlide

    this.innerElements.splice(index, 0, item)

    // build a frame and slide to a currentSlide
    this.buildSliderFrame()

    if (callback) {
      callback.call(this)
    }
  }

  /**
   * Prepernd item to carousel.
   * @param {HTMLElement} item - Item to prepend.
   * @param {function} callback - Optional callback to call after prepend.
   */
  prepend (item, callback) {
    this.insert(item, 0)
    if (callback) {
      callback.call(this)
    }
  }

  /**
   * Append item to carousel.
   * @param {HTMLElement} item - Item to append.
   * @param {function} callback - Optional callback to call after append.
   */
  append (item, callback) {
    this.insert(item, this.innerElements.length + 1)
    if (callback) {
      callback.call(this)
    }
  }

  /**
   * Removes listeners and optionally restores to initial markup
   * @param {boolean} restoreMarkup - Determinants about restoring an initial markup.
   * @param {function} callback - Optional callback function.
   */
  destroy (restoreMarkup = false, callback) {
    this.detachEvents()

    this.selector.style.cursor = 'auto'

    if (restoreMarkup) {
      const slides = document.createDocumentFragment()
      for (let i = 0; i < this.innerElements.length; i++) {
        slides.appendChild(this.innerElements[i])
      }
      this.selector.innerHTML = ''
      this.selector.appendChild(slides)
      this.selector.removeAttribute('style')
    }

    if (callback) {
      callback.call(this)
    }
  }

  isDragActive () {
    return this.$slider.classList.contains(this.config.classes.onDrag)
  }
}

// initialize multiple
Siema.init = function (selector, opts) {
  const arr = document.querySelectorAll(selector)
  for (const el of arr) {
    new Siema(el, opts)
  }
}
