/*
  Компонент рейтинга, используется для того, чтобы выставлять 
  оценку продуктам с помощью звёздочек. Можно посмотреть в 
  действии на странице pc-build.html.
  container - селектор обёртки всех кнопок
  button.base - базовое состояние кнопки
  button.active - активное состяние кнопки
*/

import isElement from 'lodash.iselement';
import merge from 'lodash.merge';

export default function ProductRating(
  container = '.js-rating',
  {
    classes
  } = {}
) {
  let $ratingElementArr;

  if (typeof container === 'string') {
    const arr = Array.from(document.querySelectorAll(container))
    arr.forEach((el) => {
      ProductRating(el, arguments[1]);
    })
  } else if (isElement(container)) {
    init();
  }

  function getClasses (obj) {
    return merge({
      button: {
        base: 'js-rating__el',
        active: 'js-rating__el_active',
      },
    },
    obj
    )
  }

  function init () {
    classes = getClasses(classes);
    $ratingElementArr = Array.from(container.querySelectorAll(`.${classes.button.base}`));
    container.addEventListener('click', change);
  }

  function toggle (target) {
    $ratingElementArr.forEach(el => el.classList.remove(`${classes.button.active}`));
    target.classList.add(`${classes.button.active}`);
  }

  function change ({target}) {
    let isButton = target.closest(`.${classes.button.base}`);
    if (isButton) toggle(isButton);
  }
}
