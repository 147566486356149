/*
  Компонент отвечает за функционал увеличения фотографий при наведении.
  Это простая обертка для внешней Jquery библиотеки, обертка добавляет
  возможность отрубать этот функционал на определенном разрешении экрана.

  Параметры:
  selector - селектор для элемента-фотографии которую нужно увеличить;
  minViewportWidth - минимальная ширина экрана на которой плагин еще будет работать,
  если ширина меньше - он будет отрубаться если активен;
*/

import debounce from 'lodash.debounce'
import fastdom from 'fastdom'
import './jquery.zoom.js'

export default class PhotoZoom {
  constructor ({
    selector = '.photo-zoom',
    minViewportWidth = 600
  } = {}) {
    try {
      this.selector = selector
      if (document.querySelectorAll(this.selector).length === 0) return

      this.minViewportWidth = minViewportWidth
      this.active = false
      this.checkViewport()
      window.addEventListener('resize', debounce(this.checkViewport.bind(this), 300))
    } catch (e) {
      console.error('Ошибка при инициализации Jquery.Zoom', e)
    }
  }

  checkViewport () {
    if (window.innerWidth < this.minViewportWidth && this.active === true) {
      $(this.selector).trigger('zoom.destroy')
      this.active = false
    } else if (window.innerWidth >= this.minViewportWidth && this.active === false) {
      this.init()
      this.active = true
    }
  }

  init () {
    const $el = document.querySelectorAll(this.selector)
    Array.from($el).forEach((el) => {
      fastdom.measure(() => {
        if (el.naturalWidth > el.parentNode.offsetWidth || el.naturalHeight > el.parentNode.offsetHeight) {
          fastdom.mutate(() => {
            $(el)
              .wrap('<div style="width: 100%;height: 100%;display:flex;justify-content:center;align-items:center"></div>')
              .parent()
              .zoom()
          })
        }
      })
    })
  }
}
