/*
  Аргументы для конструктора Tab:
  parentSelector - селектор для родителя
  parent - DOM node родителя
  tab - класс для вкладок
  wrapper - класс для обёрток контента
  hideOnOneTab - скрываем весь блок, если доступна только одна вкладка
  onChange(current_wrapper, current_tab) - калбэк при переключении вкладок
*/

import queryString from 'query-string'
import shareToGlobal from './ShareToGlobal.js'

export default class TabManager {
  constructor ({
    parent,
    parentSelector,
    tab,
    wrapper,
    onInit,
    onChange,
    beforeChange,
    hideOnOneTab = false,
    activePostfix = '_active',
    hideClass = 'tabs-row_hide',
    toggleActiveTab = false
  }) {
    this.parent = parent === undefined ? document.querySelector(parentSelector) : parent
    if (!this.parent || this.hasBeenActivated(this.parent)) { return }

    this.parentSelector = parentSelector
    this.onInit = onInit
    this.onChange = onChange
    this.beforeChange = beforeChange

    this.tabs = Array.from(this.parent.querySelectorAll(`.${tab}`))
    this.tab_class = tab
    this.tab_class_active = tab + activePostfix
    this.tab_active = this.parent.querySelector(`.${this.tab_class_active}`)

    this.wrappers = this.parent.querySelectorAll(`.${wrapper}`)
    this.wrapper_class = wrapper
    this.wrapper_class_active = wrapper + activePostfix
    this.wrapper_active = this.parent.querySelector(
      `.${this.wrapper_class_active}`
    )

    this.toggleActiveTab = this.parent.dataset.toggleActive
      ? !!this.parent.dataset.toggleActive
      : toggleActiveTab

    this.onInit && this.onInit(this.wrapper_active, this.tab_active)
    this.checkUrlQuery()
    try {
      if (hideOnOneTab && this.tabs.length <= 1) {
        this.parent.classList.add(hideClass)
        return
      } else {
        this.setup()
      }
    } catch (e) {
      console.error('Не удалось инициализировать переключающие вкладки', e)
    }
  }

  hasBeenActivated (el) {
    if (el.dataset.tabManagerActivated) {
      return true
    } else {
      el.dataset.tabManagerActivated = true
      return false
    }
  }

  setup () {
    this.tabs.forEach(tab => {
      tab.addEventListener('click', this.onTabClick.bind(this))
    })
  }

  onTabClick (e) {
    const el = e.target.closest(`.${this.tab_class}`)
    const notCurrentParent = e.target.closest(this.parentSelector) !== this.parent
    if (el.classList.contains(this.tab_class_active) || notCurrentParent) {
      if (this.toggleActiveTab) {
        this.resetActiveTab()
        this.resetActiveWrapper()
      }
      return
    }
    this.beforeChange && this.beforeChange(this.wrapper_active, this.tab_active)
    this.resetActiveTab()
    this.resetActiveWrapper()
    this.toggle(el)
    this.onChange && this.onChange(this.wrapper_active, this.tab_active)
  }

  resetActiveTab () {
    this.tab_active && this.tab_active.classList.remove(this.tab_class_active)
  }

  resetActiveWrapper () {
    this.wrapper_active && this.wrapper_active.classList.remove(this.wrapper_class_active)
  }

  toggle (el) {
    el.classList.add(this.tab_class_active)
    const selector = `.${this.wrapper_class}[data-tab='${el.dataset.tab}']`
    const wrapper = this.parent.querySelector(selector)
    wrapper.classList.add(this.wrapper_class_active)
    this.tab_active = el
    this.wrapper_active = wrapper
  }

  toggleByTabname (tabname) {
    this.beforeChange && this.beforeChange(this.wrapper_active, this.tab_active)
    const tab = this.parent.querySelector(
      `.${this.tab_class}[data-tab='${tabname}']`
    )
    this.resetActiveTab()
    this.resetActiveWrapper()
    if (tab) { this.toggle(tab) }
    this.onChange && this.onChange(this.wrapper_active, this.tab_active)
  }

  checkUrlQuery () {
    const query = queryString.parse(location.search)
    query.tabManager = typeof query.tabManager === 'string' ? [query.tabManager] : query.tabManager
    if (query.tabManager) {
      query.tabManager.forEach(el => {
        el = el.split(':')
        if (this.parent.hasAttribute('id') && this.parent.getAttribute('id').split(' ').some(id => id === el[0])) {
          this.toggleByTabname(el[1])
        }
      })
    }
  }

  static init (param) {
    for (const el of Array.from(document.querySelectorAll(param.parentSelector))) {
      const obj = { ...param }
      obj.parent = el
      new TabManager(obj)
    }
  }
}

shareToGlobal.share('TabManager', TabManager)
